import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateCurrentToken, updateQuoteToken } from '../../actions/tokenActions'
import TradeTypeEnum from '../../enums/TradeTypeEnum'
import { mapToDgWrappedToken } from '../../helpers/wrappedTokenHelpers'
import { TokenWithApprovalContext, TradeformType, TradeType } from '../../model'
import { State } from '../../reducers'
import { pickTokenFrom, pickTokenTo } from '../../services/tokenService'
import { isNativeTokenForNetwork } from '../../utils'
import { FormVariants } from './helpers'

export function useTokens(tradeType: TradeType | null = null): {
  tokenFrom: TokenWithApprovalContext
  tokenTo: TokenWithApprovalContext
} {
  const { quoteToken, currentToken } = useSelector((state: State) => state.tokens) as {
    quoteToken: TokenWithApprovalContext
    currentToken: TokenWithApprovalContext
  }

  const tokenFrom = pickTokenFrom(tradeType, {
    currentToken,
    quoteToken,
  })

  const tokenTo = pickTokenTo(tradeType, {
    currentToken,
    quoteToken,
  })

  return { tokenFrom, tokenTo }
}

export const useUpdate = (
  tradeType: TradeType | null = null
): ((value: Partial<TokenWithApprovalContext>) => void) => {
  const reduxDispatch = useDispatch()

  return (value: Partial<TokenWithApprovalContext>): void => {
    if (tradeType === TradeTypeEnum.buy) {
      reduxDispatch(updateQuoteToken(value))
    } else {
      reduxDispatch(updateCurrentToken(value))
    }
  }
}

export function useMounted(): boolean {
  const mounted = React.useRef(false)

  React.useEffect(() => {
    mounted.current = true
  }, [])

  return mounted.current
}

export function useDgWrappedTokens({
  tokenFrom,
  tokenTo,
  formVariant,
}: {
  tokenFrom: TokenWithApprovalContext
  tokenTo: TokenWithApprovalContext
  formVariant: TradeformType
}): {
  tokenFrom: TokenWithApprovalContext
  tokenTo: TokenWithApprovalContext
} {
  if (formVariant === FormVariants.market) {
    return { tokenFrom, tokenTo }
  }
  if (tokenFrom.id && isNativeTokenForNetwork(tokenFrom)) {
    tokenFrom = mapToDgWrappedToken(tokenFrom)
  }
  if (tokenTo.id && isNativeTokenForNetwork(tokenTo)) {
    tokenTo = mapToDgWrappedToken(tokenTo)
  }

  return { tokenFrom, tokenTo }
}
